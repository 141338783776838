export function DialecticaLogo() {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 61.9" fill="#10416D" xmlSpace="preserve" width="60" height="60">
      <g id="Group_745" transform="translate(-977 781)">
        <g id="Group_620" transform="translate(976.999 -780.999)">
          <path id="Path_869" fill="#10416D" d="M23.9,47.1C12.1,46.2,3.2,35.9,4.1,24c0.5-6.8,4.2-13,10.1-16.7l0.4-0.3l-1.8-3.6
			C0.5,10.6-3.6,26.2,3.4,38.4c4.2,7.3,11.8,12,20.2,12.7c3.6,0.2,7.2-0.2,10.7-1.1L33.3,46C30.2,46.8,27.1,47.1,23.9,47.1
			L23.9,47.1z"/>
          <path id="Path_870" fill="#10416D" d="M51.1,27.2c1-14-9.5-26.1-23.4-27.1c-3.6-0.3-7.2,0.2-10.6,1.5l1.4,3.7l0,0
			c11.1-4.1,23.3,1.6,27.4,12.6c1.1,2.9,1.5,5.9,1.3,9c-0.6,5.9-3.1,13.8-10.5,17.2l1.5,3.2l1.3,2.6l2.5,5.1
			c0.2-0.1,0.3-9.8,0.5-9.9C47.6,41.2,50.5,34,51.1,27.2z"/>
        </g>
        <g id="Group_621" transform="translate(1018.454 -737.795)">
          <path id="Path_871" fill="#10416D" d="M-27.2-30c0.4,1.3,3.6,1.4,7.1,0.2c3.5-1.2,6-3.1,5.6-4.4c-0.1-0.4-0.6-0.7-1.3-0.9
			c-3.9,0-7.7,1.2-10.8,3.6C-27.1-31.2-27.3-30.6-27.2-30z"/>
          <path id="Path_872" fill="#10416D" d="M-15.5-15.4c-8,2.4-15.3,2.4-18.2,0.2c0.1,0.8,0.2,1.7,0.4,2.5c0.3,1.3,0.7,2.5,1.2,3.7
			C-32-9-32-9-32-9c5.3,1.8,11,1.4,18.4-0.8C-6.4-12-0.3-16.2,2-19.8c-0.1-0.6-0.2-1.2-0.4-1.8c-0.4-1.5-1-3-1.7-4.3
			C-1-22.3-7.2-17.9-15.5-15.4z"/>
          <path id="Path_873" fill="#10416D" d="M-12.3-4.4C-17-2.9-22.1-2.4-27-3c1.5,1.2,3.2,2.1,5,2.8c2.4,0.7,6.4,0.5,10.8-0.8
			s7.9-3.2,9.6-5c1.4-1.8,2.4-3.8,3.1-6C-2.5-8.4-7.2-5.8-12.3-4.4L-12.3-4.4z"/>
          <path id="Path_874" fill="#10416D" d="M-17.2-21.4c7-2.1,12.3-5.7,13.8-8.8c-2-1.9-4.4-3.3-7-4.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.7,2.1-3.5,5.4-9.3,7.4c-5.7,1.9-10.9,1.8-11.8-0.2c-1.1,1.7-1.8,3.6-2.3,5.6C-30.5-19.2-24.1-19.3-17.2-21.4z"/>
        </g>
      </g>
    </svg>

  );
}
